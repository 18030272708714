import React from 'react';
import Script from 'react-load-script';
import { graphql } from 'gatsby';

import {HomePageTemplate} from '../templates/home-page';
import TemplateWrapper from '../layouts';

export default class IndexPage extends React.Component {
  handleScriptLoad() {
    if (typeof window !== `undefined` && window.netlifyIdentity) {
      console.log('netlify works at elast');
      window.netlifyIdentity.on("init", user => {
        if (!user) {
          window.netlifyIdentity.on("login", () => {
            document.location.href = "/admin/";
          });
        }
      });
    }
    window.netlifyIdentity.init();
  }

  render() {
    const frontmatter = this.props.data.markdownRemark.frontmatter;

    return (
      <TemplateWrapper>
        <div>
          <Script
            url="https://identity.netlify.com/v1/netlify-identity-widget.js"
            onLoad={() => this.handleScriptLoad()}
          />
          <HomePageTemplate
            meta={frontmatter.meta}
            store={frontmatter.store}
            socialMedia={frontmatter.socialMedia}
            hero={frontmatter.hero}
            video={frontmatter.video}
            community={frontmatter.community}
            characters={frontmatter.characters}
            gameplay={frontmatter.gameplay}
            newsletter={frontmatter.newsletter}
            contact={frontmatter.contact}
            footer={frontmatter.footer}
          />
        </div>
      </TemplateWrapper>
    )
  }
}

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter:{templateKey: {eq: "home-page"}}) {
      frontmatter {
        meta {
          title
          description
        }
        socialMedia {
          name
          faIcon
          url
        }
        store {
          appStore {
            icon
            url
          }
        }
        hero {
          title
          subTitle
          description
        }
        video {
          youtubeId
          subTitle
          icon
          title
          description
          socialTitle
        }
        community {
          icon
          subTitle
          title
          description
          testimonials {
            image
            avatar
            author
          }
        }
        characters {
          icon
          subTitle
          title
          description
        }
        gameplay {
          icon
          subTitle
          title
          description
        }
        newsletter {
          icon
          subTitle
          title
          description
        }
        contact {
          icon
          subTitle
          title
          description
        }
        footer {
          legal {
            label
            url
          }
        }
      }
    }
  }
`;
